import React from 'react';
import { FaFacebook, FaInstagram, FaWhatsapp } from 'react-icons/fa';

import './Navbar.css';

const Navbar: React.FC = () => {
  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="navbar-brand">Clearview Window Cleaning</div>
        <div className="navbar-icons">
        <div className="navbar-icon">
          <a href="https://wa.me/27783413500?text=I need clean windows!">
                <FaWhatsapp />
          </a>
          </div>
          <div className="navbar-icon">
            <a href="https://www.facebook.com/Clearview91/">
              <FaFacebook />
            </a>
          </div>
          <div className="navbar-icon">
            <a href="https://www.instagram.com/clearviewdwcs">
              <FaInstagram />
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

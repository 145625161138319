import React, { useRef, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import './Home.css'
import mainPageImage from './mainPage.jpg';
import one from './one.png';
import two from './two.png';
import three from './three.png';
import emailjs from '@emailjs/browser';
import { FaWhatsapp } from 'react-icons/fa';


const Home: React.FC = () => {

  const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(false);
  const [contactMessage, setMessage] = useState(
    "Message sent, we will reply as soon as possible"
  );

  const form = useRef();


    const sendEmail = (e: any) => {
      e.preventDefault();
  
      emailjs.sendForm(
         "service_8qduay8",
        "template_ebuddzh",
        e.currentTarget,
        "user_FF3bAMcZcMuJhk54dlgy8"
      )
        .then((result) => {
          if(result){
            setSuccess(true)
          }
          }, (error) => {
            if(error){
               setFail(true)
            setMessage('Could not send your message, please contact us via Whatsapp for help.')
            }
        });
    };

    function handleScroll() {
      window.scroll({
        top: document.body.offsetHeight,
        left: 0, 
        behavior: 'smooth',
      });
    }
  

  return (
    <div className="home-container">
      <div className="home-image" style={{ backgroundImage: `url(${mainPageImage})` }}>
        <div className="home-image-overlay">
          <h1>Dirty Windows?</h1>
          <h1>We can help.</h1>
          <p>At Clearview, we are dedicated to making sure those dirty windows <br />get cleaned so that your neighbors will be jealous!</p>
          <button className="btn btn-primary" onClick={handleScroll}>Contact us</button>
        </div>
      </div>
      <div className="services">
        <h2>What we offer</h2>
        <p>Our monthly services include the following, please contact us for any enquiries:</p>
        <ul className="offer-list">
          <li>- Cleaning of all Exterior windows and balustrades</li>
          <li>- Cleaning of any hard to reach interior windows</li>
          <li>- Additionally we do remove borehole stains and small paint marks.</li>
        </ul>
        <p>For any other type of window cleaning please contact us as we have plenty of experience on domestic and commercial cleaning and there’s no job too big.</p>
        <p className='solar-section'>Please note, we now offer Solar Panel cleaning as part of our services, contact us for more information</p>
      </div>

      <div className="pricing-container">
        <h2>Pricing Per Story:</h2>
        <div className="pricing-row">
          <div className="pricing-item">
          <img src={one} alt="1" width="70" height="70"/>
            <p>R300*</p>
            <p>*Pricing is a monthly cost and is subject to change.</p>
          </div>
          <div className="pricing-item">
          <img src={two} alt="1" width="40" height="60" />
            <p>R400*</p>
            <p>*Pricing is a monthly cost and is subject to change.</p>
          </div>
          <div className="pricing-item">
          <img src={three} alt="1" width="40" height="60"/>
            <p>R500*</p>
            <p>*Pricing is a monthly cost and is subject to change.</p>
          </div>
        </div>
      </div>


      <div className="form-container">
        <h1>Contact Us</h1>
        <p>For enquiries and more information, please contact us below and we will get back to you as soon as possible.</p>
        <form onSubmit={sendEmail}>
          <label htmlFor="user_name" className="form-label">Name</label>
          <input type="text" name="user_name" id="user_name" className="form-input" />
          <label htmlFor="user_email" className="form-label">Email</label>
          <input type="email" name="user_email" id="user_email" className="form-input" />
          <label htmlFor="message" className="form-label">Message</label>
          <textarea name="message" id="message" className="form-textarea"></textarea>
          <input type="submit" value="Send" className="form-submit" />
        </form>
        <div className='whatsapp-icon'> 
        {fail && <p className='fail-message'>{contactMessage}</p>}
        {success && <p className='success-message'>{contactMessage}</p>}
        <p>Alternatively you can contact us on Whatsapp by clicking the icon below</p>
        <a href="https://wa.me/27783413500?text=I need clean windows!">
              <FaWhatsapp />
        </a>
        </div>
      </div>

    </div>
  );
};

export default Home;
